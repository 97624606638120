import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

export const query = graphql`
    query($slug: String!){
        contentfulBlogPost(slug: {eq:$slug}){
            title
            publishedDate(formatString: "MMMM DD YYYY")
            body {
                json
            }
        }
    }   
`

const BlogTemplate = (props) => {
   const post = props.data.contentfulBlogPost;
   const postTitle = props.data.contentfulBlogPost.title;
   const postDate = props.data.contentfulBlogPost.publishedDate;

   const options = {
    renderNode:{
        "embedded-asset-block": (node) =>{
            const alt = node.data.target.fields.title['en-US']
            const url = node.data.target.fields.file['en-US'].url
            return <img alt={alt} src={url} width={400} />
        }
    }
   }

  return( 
    <Layout>
        <div className="mainContentWidth">
            <h1> { postTitle }</h1>
            <p> { postDate }</p>
            { documentToReactComponents(post.body.json, options ) }
        </div>
    </Layout>
  )
};

export default BlogTemplate
